<template>
    <div class="p-project-list" v-loading="!ready">
        <div class="hd">
            <div class="page-title">项目管理</div>
        </div>
        <div class="bd" v-loading="isLoading">
            <div class="topbar" v-show="!showEdit">
                <div class="topbar-item">
                    <el-button
                        icon="el-icon-circle-plus-outline"
                        type="primary"
                        @click="toggleAddNewBox"
                        >新建项目</el-button
                    >
                </div>
                <div class="topbar-item">
                    <el-input
                        v-model="search.keyword"
                        placeholder="搜索项目名、域名"
                    ></el-input>
                </div>
                <div class="topbar-item">
                    <el-select v-model="search.status">
                        <el-option label="全部状态" :value="'all'"></el-option>
                        <el-option
                            v-for="item of projectStatusList"
                            :key="item.value"
                            :value="item.value"
                            :label="item.text"
                        ></el-option>
                    </el-select>
                </div>
                <div class="topbar-item">
                    <el-select v-model="search.pageSize">
                        <el-option
                            :value="10"
                            label="每页显示 10 条"
                        ></el-option>
                        <el-option
                            :value="20"
                            label="每页显示 20 条"
                        ></el-option>
                        <el-option
                            :value="50"
                            label="每页显示 50 条"
                        ></el-option>
                        <el-option
                            :value="100"
                            label="每页显示 100 条"
                        ></el-option>
                    </el-select>
                </div>
                <div>
                    <el-button
                        icon="el-icon-refresh-right"
                        type="warning"
                        @click="clearLocalData"
                        >清除数据缓存</el-button
                    >
                </div>
            </div>
            <div>
                <el-pagination
                    background
                    layout="prev, pager, next, total"
                    :page-size="search.pageSize"
                    :current-page.sync="search.pageIndex"
                    :total="projects.length"
                >
                </el-pagination>
            </div>
            <div
                class="add-new"
                v-if="formAddNew.show"
                v-loading="formAddNew.loading"
            >
                <div class="add-new-item">
                    <el-input
                        placeholder="项目名称"
                        v-model="formAddNew.name"
                    ></el-input>
                </div>
                <div class="add-new-item">
                    <el-button type="primary" @click="addProject"
                        >提交</el-button
                    >
                </div>
                <div class="add-new-item">
                    <el-button @click="toggleAddNewBox">取消</el-button>
                </div>
            </div>
            <div class="add-new" v-if="showEdit" style="padding-top: 10px">
                <el-form
                    :model="formEdit"
                    :rules="editRules"
                    ref="formEdit"
                    label-width="100px"
                >
                    <el-form-item label="项目ID">
                        <span v-text="formEdit.id"></span>
                    </el-form-item>
                    <el-form-item label="原项目名"
                        ><span v-text="formEdit.oldName"></span
                    ></el-form-item>
                    <el-form-item label="新项目名" prop="name">
                        <el-input v-model="formEdit.name"></el-input>
                    </el-form-item>
                    <el-form-item label="">
                        <el-button type="primary" @click="update"
                            >保存</el-button
                        >
                        <el-button @click="showEdit = false">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="data" v-show="!showEdit">
                <el-table :data="pagedProjects">
                    <el-table-column prop="id" label="ID"></el-table-column>
                    <el-table-column
                        prop="name"
                        label="项目名"
                    ></el-table-column>
                    <el-table-column label="项目状态">
                        <template scope="scope">
                            <el-tag type="info" v-if="scope.row.status === '0'"
                                >项目未开始</el-tag
                            >
                            <el-tag v-if="scope.row.status === '1'"
                                >前期资料完成</el-tag
                            >
                            <el-tag
                                type="warning"
                                v-if="scope.row.status === '2'"
                                >项目进行中</el-tag
                            >
                            <el-tag
                                type="success"
                                v-if="scope.row.status === '3'"
                                >项目已上线</el-tag
                            >
                            <el-tag
                                type="danger"
                                v-if="scope.row.status === '4'"
                                >项目异常</el-tag
                            >
                            <span
                                v-text="'已结束合作'"
                                v-if="scope.row.status === '5'"
                            ></span>
                        </template>
                    </el-table-column>
                    <el-table-column label="监测状态">
                        <template scope="scope">
                            <el-tag
                                type="success"
                                v-if="scope.row.watching === 'Y'"
                                >Yes</el-tag
                            >
                            <el-tag type="info" v-else>No</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="详情">
                        <template scope="scope">
                            <router-link
                                class="el-button el-button--success el-button--mini is-plain link-to-detail"
                                :to="{
                                    name: 'project-detail',
                                    query: { id: scope.row.id },
                                }"
                                >查看详情</router-link
                            >
                            <el-button
                                size="mini"
                                type="primary"
                                plain
                                @click="edit(scope.row)"
                                >改名</el-button
                            >
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="comment"
                        label="备注"
                    ></el-table-column>
                </el-table>
            </div>
        </div>
        <div class="ft"></div>
    </div>
</template>

<style lang="less">
.p-project-list {
    .bd {
        .topbar {
            padding: 10px 0;
            display: flex;
        }

        .topbar-item {
            padding-right: 10px;
        }

        .add-new {
            display: flex;

            &-item {
                padding-right: 10px;
            }
        }
        .link-to-detail {
            text-decoration: none;
        }

        .result-msg {
            padding-top: 12px;
        }
    }
}
</style>

<script>
import { ProjectStatus } from "../js/status.js";
import { getDataVersion, getVersion, setVersion } from "../js/tools.js";

function setPageSize(pageSize) {
    window.localStorage.setItem("mml-worker-pagesize", pageSize);
}

function getPageSize() {
    let ps = window.localStorage.getItem("mml-worker-pagesize");
    let pageSize = 10;
    if (ps && !isNaN(parseInt(ps))) {
        pageSize = parseInt(ps);
    }
    return pageSize;
}

export default {
    components: {},
    data() {
        let that = this;
        return {
            ready: false,
            isLoading: false,
            search: {
                keyword: "",
                status: "all",
                pageSize: getPageSize(),
                pageIndex: 1,
            },
            formAddNew: {
                show: false,
                loading: false,
                name: "",
            },
            showEdit: false,
            formEdit: {
                id: "",
                oldName: "",
                name: "",
            },
            editRules: {
                name: [
                    {
                        required: true,
                        message: "请填写项目名",
                        trigger: "blur",
                    },
                    {
                        min: 1,
                        max: 50,
                        message: "请输入正确的项目名",
                        trigger: "blur",
                    },
                    {
                        validator: function (rule, value, callback) {
                            let existed = false;
                            for (let i = 0; i < that.list.length; i++) {
                                let project = that.list[i];
                                if (project.name === value) {
                                    existed = true;
                                    break;
                                }
                            }
                            if (existed) {
                                callback(new Error("已存在相同的项目名"));
                            } else {
                                callback();
                            }
                        },
                        trigger: "blur",
                    },
                ],
            },
            list: [],
        };
    },
    computed: {
        projects() {
            let list = [];
            let length = this.list.length;
            let keyword = this.search.keyword.toLowerCase();
            let keywordMatch = (str) => {
                if (!str) {
                    str = "";
                }
                if (typeof str !== "string") {
                    str = "";
                }
                let match = str.toLowerCase().indexOf(keyword) > -1;
                return match;
            };
            for (let i = 0; i < length; i++) {
                // let item = this.list[i]
                let item = this.list[length - i - 1];
                let isMatch = true;
                if (
                    this.search.keyword &&
                    !(
                        keywordMatch(item.name) ||
                        keywordMatch(item.comment) ||
                        keywordMatch(item.dev_url) ||
                        keywordMatch(item.digital_url) ||
                        keywordMatch(item.online_url) ||
                        keywordMatch(item.repo_name) ||
                        keywordMatch(item.repo_link) ||
                        // keywordMatch(item.server) ||
                        keywordMatch(item.dir)
                    )
                ) {
                    isMatch = false;
                }
                if (
                    this.search.status !== "all" &&
                    item.status !== this.search.status
                ) {
                    isMatch = false;
                }
                if (isMatch) {
                    list.push(Object.assign({}, item));
                }
            }
            return list;
        },
        pagedProjects() {
            let list = this.projects;
            let result = list.slice(
                (this.search.pageIndex - 1) * this.search.pageSize,
                this.search.pageIndex * this.search.pageSize
            );
            setPageSize(this.search.pageSize);
            return result;
        },
        projectStatusList() {
            let list = ProjectStatus.getFilterList();
            let arr = [];
            list.forEach((item) => {
                arr.push({
                    text: item.text,
                    value: "" + item.value,
                });
            });
            return arr;
        },
    },
    async mounted() {
        let that = this;
        let dataVersion = await getDataVersion("projects");
        if (
            dataVersion[0].version &&
            dataVersion[0].version === getVersion("projects")
        ) {
            that.list = that.readLocalData();
            that.ready = true;
        } else {
            that.getList().then(function () {
                that.ready = true;
            });
        }
    },
    methods: {
        getList() {
            let that = this;
            return new Promise(function (resolve, reject) {
                that.$ajax("/project/v2/list", {})
                    .done(function (data) {
                        setVersion(
                            "projects",
                            (
                                data.version[0] || {
                                    name: "projecsts",
                                    version: "",
                                }
                            ).version
                        );
                        that.list = data.result;
                        that.saveLocalData();
                        resolve();
                    })
                    .fail(function () {
                        reject();
                    })
                    .always(function () {});
            });
        },
        saveLocalData() {
            window.localStorage.setItem(
                "project-list",
                JSON.stringify(this.list)
            );
        },
        readLocalData() {
            let item = window.localStorage.getItem("project-list");
            if (item) {
                return JSON.parse(item);
            } else {
                return [];
            }
        },
        clearLocalData() {
            setVersion("projects", "");
            window.localStorage.removeItem("project-list");
            window.location.reload();
        },
        toggleAddNewBox() {
            this.formAddNew.show = !this.formAddNew.show;
        },
        addProject() {
            let that = this;
            if (that.formAddNew.loading) {
                return;
            }
            that.formAddNew.loading = true;
            that.$ajax("/project/v2/add", { name: that.formAddNew.name })
                .done(function (data) {
                    that.toggleAddNewBox();
                    that.formAddNew.name = "";
                    setVersion("projects", data.version[0].version);
                    that.list = data.result;
                    that.saveLocalData();
                })
                .fail(function () {
                    //
                })
                .always(function () {
                    that.formAddNew.loading = false;
                });
        },
        edit(project) {
            this.formEdit.id = project.id;
            this.formEdit.oldName = project.name;
            this.formEdit.name = project.name;
            this.showEdit = true;
        },
        update() {
            let that = this;
            that.$refs["formEdit"].validate((valid) => {
                if (!valid) {
                    return;
                }

                if (that.isLoading) {
                    return;
                }
                that.isLoading = true;
                that.$ajax("/project/v2/update", that.formEdit)
                    .done(function (ret) {
                        setVersion("projects", ret.version[0].version);
                        that.list = ret.result;
                        that.saveLocalData();
                        that.showEdit = false;
                    })
                    .fail(function () {
                        //
                    })
                    .always(function () {
                        that.isLoading = false;
                    });
            });
        },
    },
    filters: {},
};
</script>
